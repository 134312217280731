import React, { Component } from 'react';
import { Modal as ModalAntd } from 'antd';
import classnames from 'classnames';
import { connect } from 'dva';
import { PageComponent } from 'jad-lib-react';
import { environment } from '../enviroments';
import { Order, Modal } from '../components/Order';
import { isLogin, isShowLogin } from '../utils/tool.service';
import { API } from '../utils/api';
interface state {
    newWebsite?: any;
    activePath?: any;
    history?: any;
    dispatch?: any;
    userInfo?: any;
}
@connect(({ global: { newWebsite, activePath, userInfo, visible } }) => ({
    newWebsite,
    activePath,
    userInfo,
    visible,
}))
export class Header extends Component<state> {
    constructor(props) {
        super(props);
    }
    state = {
        ordervisible: false,
        successVisible: false,
        over: false,
        isMobile: true,
    };

    toggleVisible(turn = true, isf) {
        let { ordervisible } = this.state;
        this.setState(state => {
            let successVisible = false;
            if (!turn && !isf) {
                successVisible = true;
            }
            return {
                ordervisible: turn,
                successVisible,
            };
        });
    }

    logout() {
        ModalAntd.confirm({
            title: '退出登录',
            content: '确定要退出登录吗',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                API.logout({}).then((res: any) => {
                    if (res.code == 2000) {
                        setTimeout(() => {
                            this.props.history.push('/');
                            this.props.dispatch({
                                type: 'global/updateUserInfo',
                                payload: {},
                            });
                        }, 50);
                    }
                });
            },
        });
    }
    render() {
        const newWebsite = this.props.newWebsite;
        const activePath = this.props.activePath;
        return (
            <header>
                <section className="header-wrap">
                    <div className="logo-wrap">
                        <a href="#">
                            <img
                                src={require(`../assets${newWebsite ? '/chuli' : ''}/logo.png`)}
                                alt=""
                            />
                        </a>
                    </div>
                    <nav>
                        <a
                            href="#"
                            className={classnames({
                                active: activePath == '/',
                            })}
                        >
                            广告投放
                        </a>
                        <a
                            href="#/realization"
                            className={classnames({
                                active: activePath == '/realization',
                            })}
                        >
                            媒体变现
                        </a>
                        {!newWebsite && (
                            <a
                                href="https://www.hzjizhun.com/docs#/ad-JS"
                                target="_blank"
                                className={classnames({
                                    active: activePath == '/44',
                                })}
                            >
                                对接文档
                            </a>
                        )}
                        <span
                            className={classnames('about-us-down', {
                                active:
                                    ['/about', '/contact-us', '/team'].indexOf(activePath) != -1,
                            })}
                        >
                            关于我们
                            <ul className="dropmenu">
                                <li
                                    className={classnames('is-submenu-item', {
                                        active: activePath == '/product',
                                    })}
                                >
                                    <a href="#/product">产品简介</a>
                                </li>
                                <li
                                    className={classnames('is-submenu-item', {
                                        active: activePath == '/about',
                                    })}
                                >
                                    <a href="#/about">公司简介</a>
                                </li>
                                {!newWebsite && (
                                    <li
                                        className={classnames('is-submenu-item', {
                                            active: activePath == '/contact-us',
                                        })}
                                    >
                                        <a href="#/contact-us">联系我们</a>
                                    </li>
                                )}
                                <li
                                    className={classnames('is-submenu-item', {
                                        active: activePath == '/team',
                                    })}
                                >
                                    <a href="#/team">商务合作</a>
                                </li>
                            </ul>
                        </span>
                    </nav>
                    <div>
                        <div className="col-r" onClick={this.toggleVisible.bind(this)}>
                            <a className="link">预约咨询</a>
                        </div>
                        {this.props.userInfo.userRoleInfo ? (
                            <div className="col-r" style={{ marginLeft: '17px' }}>
                                <a className="link" href={environment.back}>
                                    进入后台
                                </a>
                            </div>
                        ) : isLogin() ? (
                            <div className="col-r" style={{ marginLeft: '17px' }}>
                                <a href="#/apply-for" className="link">
                                    代理商开户
                                </a>
                            </div>
                        ) : null}
                        {isShowLogin() ? (
                            this.props.userInfo.id ? (
                                <div
                                    className="col-r"
                                    onClick={this.logout.bind(this)}
                                    style={{ marginLeft: '17px' }}
                                >
                                    <a className="link">退出登录</a>
                                </div>
                            ) : !newWebsite ? (
                                <div className="col-r" style={{ marginLeft: '17px' }}>
                                    <a href="#/login" className="link">
                                        登录/注册
                                    </a>
                                </div>
                            ) : null
                        ) : null}
                    </div>
                </section>
                {this.state.ordervisible ? (
                    <Order toggleVisible={this.toggleVisible.bind(this)} />
                ) : null}
                {this.state.successVisible ? (
                    <Modal
                        onClose={() => {
                            this.setState({
                                successVisible: false,
                            });
                        }}
                    />
                ) : null}
            </header>
        );
    }
}
